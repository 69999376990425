import React, { PropsWithChildren } from 'react';
import './App.css';
import { AppBar, Toolbar, Typography, Container, Box, AlertTitle, Alert } from '@mui/material';
import LicensingStrategy from './LicensingStrategy';

const AppFrame: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">
            RFC-60: Marketplace Monetisation Strategy Exploration
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <Box my={4}>
          {children}
        </Box>
      </Container>
    </Box>
  );
}

const App = () => {
  return (
    <AppFrame>
      <Typography variant="h3" marginBottom={2}>
        Exploring the options introduced by RFC-60
      </Typography>
      <Typography variant="body1" marginBottom={1}>
        Atlassian's RFC-60 introduces a series of additional <strong>cloud</strong> licensing strategies which go beyond the current Marketplace licensing model.
      </Typography>
      <Typography variant="body1" marginBottom={1}>
        Rather than being forced to license applications by the total number of users in the host product's licensed users, Marketplace Partners will be able to choose from a range of licensing strategies to suit their business model.
      </Typography>
      <Typography variant="body1" marginBottom={1}>
        This page is here to help partners explore the new licensing strategies and understand how they might apply to their apps.
      </Typography>
      <Alert severity="info">
        <AlertTitle>Please Note</AlertTitle>
        <table>
          <tr>
            <td>
              <img src="./dontpanic.jpg" width="200" alt="A thumbsup in front of a planet, with the text Don't Panic under it, in black and white."/>
            </td>
            <td>
              <strong>Don't panic!</strong> The only <em>mandatory</em> change that is coming, is that your app will need to have multi-instance pricing for Cloud Enterprise customers. Most Marketplace Partners have a low number of Enterprise customers, so this change will likely have a minimal impact on your business.
              <br /><br />
              There is an opportunity to consider multi-instance features which are of interest to Cloud Enterprise customers, such as cross-instance configuration management or reporting, and it is worth considering the added value potential there. Premium Support is likely to also be of interest to these customers.
              <br /><br />
              While there are a slew of new licensing strategies, they are likely to be of interest to Marketplace Apps which do not have legacy pricing structures. We expect this will make it harder in the short term for customers to compare pricing between apps that have different licensing strategies.
            </td>
          </tr>
        </table>

      </Alert>

      <Typography variant="h3" marginTop={3} marginBottom={2}>
        Licensing Strategies
      </Typography>

      <Typography variant="h4" marginBottom={2}>
        Existing Strategy
      </Typography>

      <LicensingStrategy
        heading={<>
          <Typography variant="h5" marginBottom={2}>
            Matching the Atlassian Product Users (Aka the current licensing model)
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            This strategy allows you to charge a different price per user for a certain band of users in the host product. For example, you might charge $20 per users if the instance has between 10 and 100 users, but only $15 per user if the instance has between 101 and 200 users, and so on.
          </Typography>
          <Alert severity="info">
            <AlertTitle>Using this Table</AlertTitle>
            We have populated the table with some example bands and prices. You can adjust these to match your current pricing model, using the information you get from the Atlassian Marketplace reports. You will need to aggregate the total number of active users across all installs for each band.

            <br /><br />
            <strong>Please note:</strong> this model currently ignores the evaluation period, and difference in price for new vs renewals, and discounts for annual, non-commercial and partner licenses. These might introduce inaccuracies in the total revenue calculations.
          </Alert>
        </>}
      />

      <Typography variant="h4" marginBottom={2}>
        New Strategies
      </Typography>

      <LicensingStrategy
        heading={<>
          <Typography variant="h5" marginBottom={2}>
            Multi-Instance License (Total Unique Users across instances) - <strong>Required</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Previously, a customer would need to license users for the total number of users on each of their instances. So if they had 3 instance with the 3,000 users on each, they had to license them all - even if 2,000 of them were the same unique users across the instances.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            This new strategy allows a customer to license their <em>unique users across all their instances</em>. As this is a capability only available to <em>Enterprise</em> customers, there's an opportunity to provide multi-instance specific capabilities and functionality (such as centralisation / synchronisation of configuration and/or data, and premium services like premium support in the first year).
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            If you're goal is to be revenue neutral, you would need to increase the price per user to account for the reduction in the duplicate licensing across the instances.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Be aware that for customers that have multiple instances with unique users isolated to each instance, this strategy may push the customer into a higher bracket which has a lower price per user, resulting in less revenue for this customer.

            {/*
          To help you, as an App Vendor, understand the impact of this change, you would need to:
            For a given customer, find the total number of unique users across all their instances, and the total number of users across each of their instances.
            3x 2,000 users = 6,000 unique users -- moves up to a higher band, less revenue for the same number of users
            3x 2,000 users = 2,000 unique users -- stays in the same bracket, 1/3 revenue
          
          As an App Vendoer you already know:
            The minimum number of unique users, by using the number of users from the smallest instance.
            The maximum number of unique users, by summing the number of users across all instances.

          MORE TO ADVISE IN THIS SPACE
          ... we want to get tot the point of showing the delta change in revenue between current pricing and new strategies.
          Express it as a percentage change - based on the difference in unique vs total users ... similar for other strategies

          NOTE THAT the customer where the customer gets the same app across the same instances, they will want to pay the same or less money.
          */}
          </Typography>
          <Alert severity="info">
            <AlertTitle>Please Note</AlertTitle>
            Most Marketplace Vendors have a proportionally small number of Jira Enterprise customers, so the impact of this change is likely to be minimal. 
            <br></br>
            The opportunity to provide additional value to these customers is significant, so this section for most will be about driving growth.
          </Alert>
        </>}
      />

      <LicensingStrategy
        heading={<>
          <Typography variant="h5" marginBottom={2}>
            User Based License (per instance) - <strong>Optional</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Instead of licensing based on the total number of users in the host product, you can license based on a select subset of users on that instance (as defined by a permission group).
            This gives your customers the opportunity to buy your app only for their power users. 
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            As an app vendor this type of licensing might be interesting if your app is focussed on a small group of (power) users. (For example: Admin Tools) 
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            If you want to use this type of licensing it's important to note the specific users it's targeted add and clearly showcase the value your add can bring.
            You'll need to do this as you'll have to charge a higher per user price than for a regular full instance app.
          </Typography>
        </>}
      />

      <LicensingStrategy
        heading={<>
          <Typography variant="h5" marginBottom={2}>
            Jira Service Management Based License (per instance) - <strong>Optional</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Instead of licensing based on the total number of users in the host product, this licensing strategy focusses on the total number of JSM users.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Admins, Agents, and Collaborators are considered JSM users, customers are not considered JSM users.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            This licensing strategy is ideal for apps supporting JSM Agents specifically.
          </Typography>
        </>}
      />

      <Typography variant="h3" marginTop={3} marginBottom={2}>
        FAQ
      </Typography>
      <ul>
        <li>Apps will not be able to choose multiple licensing strategies beyond a transitionary period</li>
      </ul>
      <Typography variant="h3" marginTop={3} marginBottom={2}>
        Unresolved Questions
      </Typography>
      <Typography variant="body1" marginBottom={1}>
        There are a number of open questions which we are trying to answer:
      </Typography>
      <ul>
        <li>Do app standard/advanced packaging have to share the same user bands, and just vary on price per user alone?</li>
        <li>Can per user licensing be used with multi-instance licensing?</li>
        <li>We don't yet know to what degree and in what form that multiple co-existing licensing strategies might take.</li>
        <li>Can an app require that a customer licenses both per user <em>and</em> based on usage? (e.g. pay for project admins to define the report, and usage of the report generation when viewed by any user)</li>
        <li>It has been stated that beyond a transitionary period, apps will not be able to offer multiple pricing options. Does this mean that multi-instance licenses can never be combined with usage or per user pricing?</li>
      </ul>
    </AppFrame>
  );
}

export default App;
